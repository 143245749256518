export const shuffleArrayAndFixed = (originalList) => {
  // Cria uma nova lista somente com os objetos que não têm a propriedade "fixed" ou que têm a propriedade "fixed" com o valor "false"
  const newList = originalList.filter(
    (obj) => !obj.fixed || obj.fixed === false
  );

  // Embaralha a nova lista
  newList.sort(() => Math.random() - 0.5);

  // Concatena a nova lista embaralhada com a lista original filtrada
  const shuffledList = newList.concat(
    originalList.filter((obj) => obj.fixed === true)
  );

  // Imprime a lista embaralhada
  return shuffledList;
};

/**
 * Retorna se todos os valores do array estão iguais
 * @param {[]} originalArray Array original a ser comparado, não sofre alterações
 * @param {[]} arrayToCompare Array comparador. seus valores serão comparados e mudam dinamicamente
 * @returns {boolean}
 */
export const isEqual = (originalArray, arrayToCompare) => {
  let equal = true;
  arrayToCompare = arrayToCompare || [];

  originalArray.forEach((item) => {
    if (equal && !arrayToCompare.includes(item)) {
      equal = false;
    }
  });

  return equal;
};
